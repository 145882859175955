import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.user.isAdFree)?_c('AdBTWR'):_vm._e(),_c(VRow,{staticClass:"mt-10",attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","sm":"6"}},[_c(VCard,[_c('h1',{staticClass:"page-header font-weight-light pt-5"},[_vm._v("Verify Email Address")]),(_vm.verificationLoading)?_c(VProgressCircular,{staticClass:"ma-10",attrs:{"size":"72","color":"primary","indeterminate":""}}):_vm._e(),_c(VFabTransition,[(_vm.verificationComplete)?_c('div',{staticClass:"stack-icon pa-10"},[_c(VIcon,{staticClass:"stack-icon-bottom",attrs:{"color":"white","size":"90"}},[_vm._v(" mdi-circle ")]),_c(VIcon,{staticClass:"stack-icon-top",attrs:{"size":"95","color":"success"}},[_vm._v(" mdi-check-circle ")])],1):_vm._e()]),(_vm.verificationComplete)?_c('h3',{staticClass:"title font-weight-light pb-10"},[_vm._v(" Thank you for verifying your email address. ")]):_vm._e(),(_vm.verificationComplete)?_c('h3',{staticClass:"subtitle-1 font-weight-light pb-10"},[_vm._v(" Redirecting you to the sign in page... ")]):_vm._e(),_c(VFabTransition,[(_vm.verificationFailed)?_c('div',{staticClass:"stack-icon pa-10"},[_c(VIcon,{staticClass:"stack-icon-bottom",attrs:{"color":"white","size":"90"}},[_vm._v(" mdi-circle ")]),_c(VIcon,{staticClass:"stack-icon-top",attrs:{"size":"95","color":"error"}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()]),(_vm.verificationFailed)?_c('h3',{staticClass:"title font-weight-light pb-5"},[_vm._v(" An error occurred while verifying your email address. ")]):_vm._e(),(_vm.verificationFailed && _vm.verificationFailedMessage)?_c('h4',{staticClass:"subtitle-1 font-weight-light pb-10"},[_vm._v(" "+_vm._s(_vm.verificationFailedMessage)+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }